<template>
  <div>

    <setting-therapy-protocol-own-list-form-modal v-model="showEditForm" :protocolown="currentRow" @after-save="onAfterSaveProtocolOwn"></setting-therapy-protocol-own-list-form-modal>


    <el-dialog
        v-model="showSelectTherapyForm"
        width="97%">

      <patient-therapy click-action="therapy-protocol-own-list" @on-therapy-protocol-select="onTherapyProtocolSelect"></patient-therapy>

      <template #footer>
        <el-row>
          <el-col :span="24" class="dialog-footer text-left">
            <el-button @click="showSelectTherapyForm = false">Abbrechen</el-button>
          </el-col>
        </el-row>

      </template>
    </el-dialog>

    <el-button type="primary" @click="onBtnNewClick" size="mini" icon="el-icon-circle-plus">Protokoll hinzufügen</el-button>

    <el-table
        ref="protocolOwnTable"
        :data="tableData"
        :default-sort = "{prop: 'title', order: 'ascending'}"
        style="width: 100%"
        highlight-current-row
        @row-click="onRowEditClick"
        stripe>
      <el-table-column
          prop="title"
          label="Titel"
          sortable>
      </el-table-column>
      <el-table-column
        prop="kz_title"
        label="Kürzel"
        sortable>
      </el-table-column>
      <el-table-column
        prop="meta.protocol.title"
        label="DACY Protokoll"
        sortable>
      </el-table-column>
      <el-table-column
          width="150">
        <template #default="scope">
          <div class="text-right">
            <el-button size="mini" type="warning" @click="onRowEditClick(scope.row)" icon="el-icon-edit"></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          width="70">
        <template #default="scope">
          <div class="text-right">
            <el-popconfirm
                :title="'Sie möchten dieses Protokoll aus der Hausliste löschen?'"
                confirmButtonText='Ja, löschen'
                confirmButtonType="danger"
                cancelButtonText='Nein'
                iconColor="red"
                @confirm="onRowDeleteConfirmed(scope.row)">
              <template #reference>
                <el-button size="mini" type="warning" icon="el-icon-delete"></el-button>
              </template>
            </el-popconfirm>

          </div>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PatientTherapy from "../patient/PatientTherapy";
import SettingTherapyProtocolOwnListFormModal from "./SettingTherapyProtocolOwnListFormModal";
const _ = require('lodash');

export default {
  name: "SettingTherapyProtocolOwnList",
  props: [],
  components: {PatientTherapy, SettingTherapyProtocolOwnListFormModal},
  data() {
    return {
      tableData: [],
      currentRow: null,
      showEditForm: false,
      showSelectTherapyForm: false
    }
  },
  mounted: async function () {
    await this.getProtocolOwn()
  },
  computed: {
    ...mapGetters('protocolown', ['protocolown']),
  },
  watch: {
    protocolown: function (val) {
      this.tableData = _.cloneDeep(val)
    }
  },
  methods: {
    ...mapActions('protocolown', ['getProtocolOwn', 'deleteProtocolOwn', 'saveProtocolOwn']),
    async onRowDeleteConfirmed(row) {
      await this.deleteProtocolOwn(row)
      this.showEditForm = false
      await this.getProtocolOwn()
    },
    onBtnNewClick() {
     this.showSelectTherapyForm = true
    },
    onRowEditClick(row) {
      this.currentRow = row
      this.showEditForm = true
    },
    async saveRow() {
      await this.saveProtocolOwn(this.currentRow)
      this.showEditForm = false
      this.showSelectTherapyForm = false
      await this.getProtocolOwn()
    },
    onTherapyProtocolSelect(protocol) {
      if(!protocol) return
      this.currentRow = {
        id: null,
        title: protocol.title,
        kz_title: protocol.kz_title,
        protocol_id: protocol.id,
        meta: {
          protocol: protocol
        }
      }
      this.showEditForm = true
    },
    onAfterSaveProtocolOwn() {
      this.getProtocolOwn()
      this.showEditForm = false
    }
  }
}
</script>
<style scoped>
</style>
