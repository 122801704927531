<template>
  <div>

    <el-dialog
        v-if="currentRow"
        :title="'DACY-Benutzer ' + (currentRow.username ? currentRow.username : '') + (currentRow.id ? ' bearbeiten' : ' hinzufügen')"
        v-model="showEditForm">

      <el-tabs>
        <el-tab-pane label="Stammdaten">

          <el-row class="mt-4">
            <el-col :span="11">
              <h6>Benutzername</h6>
              <el-input
                placeholder="Benutzername"
                v-model="currentRow.username"></el-input>
            </el-col>
            <el-col :span="11" :offset="1">
              <h6>E-Mail</h6>
              <el-input
                placeholder="E-Mail Adresse"
                v-model="currentRow.email"></el-input>
            </el-col>
          </el-row>
          <el-row class="mt-4">
            <el-col :span="11">
              <h6>Passwort</h6>
              <el-input
                type="password"
                show-password
                placeholder="Passwort"
                v-model="currentRow.password"></el-input>

              <div class="mt-2">Hier bitte nur etwas eintragen, wenn das bestehende Passwort verändert werden soll.</div>

            </el-col>

          </el-row>
          <el-row class="mt-4">
            <el-col :span="24" >
              <h6>Rollen</h6>
              <el-select class="w-100" v-model="currentRow.roles" multiple>
                <el-option
                  v-for="item in user_roles"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-row>

          <el-row class="mt-4">
            <el-col :span="24" class="dialog-footer text-right">
              <el-button>Neue Zugangsdaten senden</el-button>
              <el-button @click="showEditForm = false">Abbrechen</el-button>
              <el-button type="primary" @click="saveRow()">{{(currentRow.id ? ' Speichern' : 'Hinzufügen')}}</el-button>
            </el-col>
          </el-row>

        </el-tab-pane>
        <el-tab-pane label="CRO Zugangsdaten" v-if="modules_enabled.survey" :disabled="!currentRow.id">

          Bitte wählen Sie hier eine CRO aus, für die Sie Zugangsdaten hinterlegen oder ändern wollen.

          <hr/>

          <el-select
            v-model="currentCro"
            value-key="id"
            filterable
            class="mt-3">
            <el-option
              v-for="item in cro"
              :key="item.id"
              :label="item.title"
              :value="item"
            />
          </el-select>

          <div class="mt-4" v-if="currentCro">
            <h6><u>Zugangsdaten für {{currentCro.title}}</u></h6>
            <el-row class="mt-4">
              <el-col :span="11">
                <h6>Benutzername</h6>
                <el-input
                  placeholder="Benutzername"
                  v-model="currentUserCro.username"></el-input>
              </el-col>
              <el-col :span="11" :offset="1">
                <h6>Passwort</h6>
                <el-input
                  type="password"
                  show-password
                  placeholder="Passwort"
                  v-model="currentUserCro.password"></el-input>
              </el-col>
            </el-row>
            <el-row class="mt-4">
              <el-col :span="24" class="dialog-footer text-right">
                <el-button @click="showEditForm = false">Abbrechen</el-button>
                <el-button type="primary" @click="saveCro">Zugangsdaten für {{currentCro.title}} speichern</el-button>
              </el-col>
            </el-row>
          </div>


        </el-tab-pane>
      </el-tabs>


    </el-dialog>

    <el-button type="primary" @click="onBtnNewClick" size="mini" icon="el-icon-circle-plus">DACY-Benutzer hinzufügen</el-button>

    <el-table
        ref="usersTable"
        :data="tableData"
        :default-sort = "{prop: 'title', order: 'ascending'}"
        style="width: 100%"
        highlight-current-row
        @row-click="onRowEditClick"
        stripe>
      <el-table-column
          prop="username"
          label="Benutzername"
          sortable>
      </el-table-column>
      <el-table-column
        prop="email"
        label="E-Mail"
        sortable>
      </el-table-column>
      <el-table-column
        label="Rollen"
        sortable>
        <template #default="scope">
          <el-tag type="warning" class="mr-1" v-for="role in scope.row.roles" :key="role.id">{{role.name}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          width="150">
        <template #default="scope">
          <div class="text-right">
            <el-button size="mini" type="warning" @click="onRowEditClick(scope.row)" icon="el-icon-edit"></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          width="70">
        <template #default="scope">
          <div class="text-right">
            <el-popconfirm
                :title="'Sie möchten diesen DACY Benutzer löschen?'"
                confirmButtonText='Ja, löschen'
                confirmButtonType="danger"
                cancelButtonText='Nein'
                iconColor="red"
                @confirm="onRowDeleteConfirmed(scope.row)">
              <template #reference>
                <el-button size="mini" type="warning" icon="el-icon-delete"></el-button>
              </template>
            </el-popconfirm>

          </div>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
const _ = require('lodash');

export default {
  name: "SettingUser",
  props: [],
  components: {},
  data() {
    return {
      tableData: [],
      currentRow: null,
      currentCro: null,
      currentUserCro: {},
      showEditForm: false
    }
  },
  mounted: async function () {
    await this.getUsers()
    await this.getCRO()
    await this.getUserRoles()
  },
  computed: {
    ...mapGetters('users', ['users', 'user_roles']),
    ...mapGetters('config', ['modules_enabled']),
    ...mapGetters('cro', ['cro']),
  },
  watch: {
    users: function (val) {
      this.tableData = _.cloneDeep(val)
    },
    currentCro: async function () {
      if(!this.currentCro || !this.currentRow) return
      this.loadUserCro()
    }
  },
  methods: {
    ...mapActions('users', ['getUsers', 'deleteUser', 'saveUser', 'getUsersCro', 'saveUsersCro', 'getUserRoles']),
    ...mapActions('cro', ['getCRO']),
    async loadUserCro() {
      this.currentUserCro = {}
      const result = await this.getUsersCro( { user_id: this.currentRow.id, cro_id: this.currentCro.id })
      const filteredResult = _.find(result, (o) => o.cro_id == this.currentCro.id)
      if(filteredResult) this.currentUserCro = filteredResult
      else this.currentUserCro = { id: 0, cro_id: this.currentCro.id }
    },
    async onRowDeleteConfirmed(row) {
      await this.deleteUser(row)
      this.showEditForm = false
      await this.getUsers()
    },
    onBtnNewClick() {
      this.currentRow = {
        id: null,
        title: '',
        roles: []
      }
      this.showEditForm = true

    },
    onRowEditClick(row) {
      this.currentRow = _.cloneDeep(row)
      this.currentRow.roles = this.currentRow.roles.map((r) => r.id)
      this.showEditForm = true
    },
    async saveRow() {
      if(!this.currentRow.roles || this.currentRow.roles.length === 0) {
        this.$notify.error({
          message: "Bitte wählen Sie eine Rolle",
          position: 'bottom-left'
        });
        return
      }
      if(!this.currentRow.username || this.currentRow.username == '') {
        this.$notify.error({
          message: "Bitte geben Sie einen Benutzernamen an",
          position: 'bottom-left'
        });
        return
      }
      await this.saveUser(this.currentRow)
      this.showEditForm = false
      await this.getUsers()
    },
    async saveCro() {
      const result = await this.saveUsersCro({ user_id: this.currentRow.id, cro: this.currentUserCro })
      if(!result) {
        this.$notify.error({
          message: "Ein Fehler trat beim Speichern der Zugangsdaten auf",
          position: 'bottom-left'
        });
      }
      else {
        this.loadUserCro()
        this.$notify({
          message: 'Zugangsdaten wurden gespeichert',
          type: 'success',
          position: 'bottom-left'
        });
      }
    }
  }
}
</script>
<style scoped>
</style>
