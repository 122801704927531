<template>
  <div>

    <el-dialog
        v-if="currentRow"
        :title="'Karteikartentyp ' + currentRow.title + (currentRow.id ? ' bearbeiten' : ' hinzufügen')"
        v-model="showEditForm">
      <el-row class="mt-4">
        <el-col :span="12">
          <h6>Bezeichnung</h6>
          <el-input
              placeholder="Bezeichnung"
              v-model="currentRow.title"></el-input>
        </el-col>
        <el-col :span="11" :offset="1">
          <h6>Kürzel</h6>
          <el-input
            placeholder="Kürzel"
            v-model="currentRow.code"></el-input>
        </el-col>
      </el-row>

      <template #footer>
        <el-row>
          <el-col :span="10" class="text-left">
            <el-popconfirm
                v-if="currentRow.id"
                title="Sie möchten diesen Karteikartentyp löschen?"
                confirmButtonText='Ja, löschen'
                confirmButtonType="danger"
                cancelButtonText='Nein'
                iconColor="red"
                @confirm="onRowDeleteConfirmed(currentRow)">
              <template #reference>
                <el-button type="danger">Löschen</el-button>
              </template>
            </el-popconfirm>
          </el-col>
          <el-col :span="14" class="dialog-footer text-right">
            <el-button @click="showEditForm = false">Abbrechen</el-button>
            <el-button type="primary" @click="saveRow()">{{(currentRow.id ? ' Speichern' : 'Hinzufügen')}}</el-button>
          </el-col>
        </el-row>

      </template>
    </el-dialog>

    <el-button type="primary" @click="onBtnNewClick" size="mini" icon="el-icon-circle-plus">Karteikartentyp hinzufügen</el-button>

    <el-table
        ref="filecardtypeTable"
        :data="tableData"
        :default-sort = "{prop: 'title', order: 'ascending'}"
        style="width: 100%"
        highlight-current-row
        @row-click="onRowEditClick"
        stripe>
      <el-table-column
        prop="code"
        label="Kürzel"
        sortable>
      </el-table-column>
      <el-table-column
          prop="title"
          label="Bezeichnung"
          sortable>
      </el-table-column>
      <el-table-column
          width="150">
        <template #default="scope">
          <div class="text-right">
            <el-button size="mini" type="warning" @click="onRowEditClick(scope.row)" icon="el-icon-edit"></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          width="70">
        <template #default="scope">
          <div class="text-right">
            <el-popconfirm
                :title="'Sie möchten diesen Karteikartentyp löschen?'"
                confirmButtonText='Ja, löschen'
                confirmButtonType="danger"
                cancelButtonText='Nein'
                iconColor="red"
                @confirm="onRowDeleteConfirmed(scope.row)">
              <template #reference>
                <el-button size="mini" type="warning" icon="el-icon-delete"></el-button>
              </template>
            </el-popconfirm>

          </div>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
const _ = require('lodash');

export default {
  name: "SettingFileCardType",
  props: [],
  components: {},
  data() {
    return {
      tableData: [],
      currentRow: null,
      showEditForm: false
    }
  },
  mounted: async function () {
    await this.getFileCardTypes()
  },
  computed: {
    ...mapGetters('filecardtypes', ['filecardtypes']),
  },
  watch: {
    filecardtypes: function (val) {
      this.tableData = _.cloneDeep(val)
    }
  },
  methods: {
    ...mapActions('filecardtypes', ['getFileCardTypes', 'saveFileCardType', 'deleteFileCardType']),
    async onRowDeleteConfirmed(row) {
      await this.deleteFileCardType(row)
      this.showEditForm = false
      await this.getFileCardTypes()
    },
    onBtnNewClick() {
      this.currentRow = {
        id: null,
        title: '',
        code: ''
      }
      this.showEditForm = true

    },
    onRowEditClick(row) {
      this.currentRow = row
      this.showEditForm = true
    },
    async saveRow() {
      await this.saveFileCardType(this.currentRow)
      this.showEditForm = false
      await this.getFileCardTypes()
    }
  }
}
</script>
<style scoped>
</style>
