<template>
  <div>
    <el-row>
      <el-col :span="12">
        <h4 class="mt-2 mb-3">Patienten Import</h4>
        <p>Wenn Sie eine JSON Datei aus einer anderen DACY Instanz haben, können Sie diese hier importieren.</p>

        <el-upload
          limit="1"
          v-model:file-list="fileList"
          :action="uploadURL"
          :headers="{ Authorization: 'Bearer ' + uploadURLToken}"
          drag
          :on-success="onSuccessFileUpload"
          :on-remove="onRemoveFileUpload"
        >
          <el-icon class="el-icon-upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            JSON Datei hier her ziehen oder <em>klicken für Auswahl</em>
          </div>
        </el-upload>

        <hr/>
        <div class="text-right">
          <el-button @click="sendForm" :disabled="uploading || form.files.length == 0" type="warning">Patienten-Datei importieren</el-button>
        </div>

      </el-col>
    </el-row>

  </div>
</template>

<script>
import axios from "axios";
import DacyServer from "../../dacyserver";
import _ from "lodash";

export default {
  name: "SettingImport",
  props: [],
  components: {},
  data() {
    return {
      fileList: [],
      form: {
        files: []
      },
      uploading: false
    }
  },
  mounted: async function () {

  },
  computed: {
    uploadURL: function() {
      return DacyServer.getInstance().getURL() + "/upload"
    },
    uploadURLToken: function() {
      return this.$auth.token()
    }
  },
  watch: {
  },
  methods: {
    onSuccessFileUpload(response) {
      this.form.files.push(response.filename)
    },
    onRemoveFileUpload(file) {
      _.remove(this.form.files, function(f) {
        return f == file.response.filename
      })
    },
    async sendForm() {

      this.uploading = true

      if(!this.form.files || this.form.files.length === 0) {
        this.$message({
          message: "Bitte eine JSON Datei hochladen",
          type: 'error'
        });
      }
      else {

        let result
        try {
          result = await axios.post('/import/patient', this.form)
        }
        catch(e) {
            this.$message({
              message: "Fehler beim Importvorgang",
              type: 'error'
            });
         }

        if(result.data.error) {
          this.$message({
            message: "Dieser Patient wurde bereits importiert. DACY ID: #" + result.data.patient.id +", EDID #" + (result.data.patient.edids && result.data.patient.edids.length ? result.data.patient.edids[0].edid : ''),
            type: 'error'
          });
        }
        else {
          this.form.files = []
          this.fileList = []
          this.$message({
            message: "Die Datei wurde importiert! DACY ID: #" + result.data.id,
            type: 'success'
          });
        }

      }

      this.uploading = false
    }
  }
}
</script>
<style scoped>
</style>
