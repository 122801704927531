<template>
  <div>
    <el-row>
      <el-col :span="12">
        <h4 class="mt-2 mb-3">Patienten PDF</h4>
        <p>Erstellen Sie eine PDF-Datei mit allen Patientendaten.
        Klicken Sie bitte dazu auf den folgenden Button um die Aktion auf dem Server zu starten.
        Nach Fertigstellung erhalten Sie eine Nachricht und können das PDF herunterladen.</p>
        <el-popconfirm
            class="mt-3"
            title="Möchten Sie den Export starten?"
            confirmButtonText='Ja, Export starten'
            confirmButtonType="warning"
            cancelButtonText='Nein'
            iconColor="warning"
            @confirm="startPDFExport">
          <template #reference>
            <el-button :disabled="pdfExportStarted" type="warning">PDF Export starten</el-button>
          </template>
        </el-popconfirm>

        <hr/>

        <h4 class="mt-2 mb-3">Datenbank Export</h4>
        <p>Erstellen Sie eine SQL Backup-Datei mit allen Patientendaten.
          Klicken Sie bitte dazu auf den folgenden Button um die Aktion auf dem Server zu starten.
          Nach Fertigstellung erhalten Sie eine Nachricht und können die Datei herunterladen.</p>
        <el-popconfirm
          class="mt-3"
          title="Möchten Sie den Export starten?"
          confirmButtonText='Ja, Export starten'
          confirmButtonType="warning"
          cancelButtonText='Nein'
          iconColor="warning"
          @confirm="startDatabaseExport">
          <template #reference>
            <el-button :disabled="databaseExportStarted" type="warning">Datenbank Export starten</el-button>
          </template>
        </el-popconfirm>

      </el-col>
    </el-row>

  </div>
</template>

<script>
import axios from "axios";
import socket from "@/socket";

export default {
  name: "SettingExport",
  props: [],
  components: {},
  data() {
    return {
      pdfExportStarted: false,
      databaseExportStarted: false
    }
  },
  mounted: async function () {

    socket.on('export-patient-all-finished', () => {
      this.pdfExportStarted = false
    })

    socket.on('export-database-finished', () => {
      this.databaseExportStarted = false
    })

  },
  computed: {
  },
  watch: {
  },
  methods: {
    async startPDFExport() {
      this.pdfExportStarted = true
      await axios.get('/export/patients/pdf/all/start')
    },
    async startDatabaseExport() {
      this.databaseExportStarted = true
      await axios.get('/export/database')
    }
  }
}
</script>
<style scoped>
</style>
