<template>
  <div>

    <el-dialog
      v-if="currentRow"
      :title="'Protokoll ' + currentRow.title + (currentRow.id ? ' bearbeiten' : ' zur Hausliste hinzufügen')"
      v-model="showForm"
      :before-close="closeDialog">
      <setting-therapy-protocol-own-list-form @after-save="closeDialog" :protocolown="currentRow"></setting-therapy-protocol-own-list-form>
    </el-dialog>

  </div>
</template>

<script>
import SettingTherapyProtocolOwnListForm from "./SettingTherapyProtocolOwnListForm";
export default {
  name: "SettingTherapyProtocolOwnListFormModal",
  props: ['modelValue', 'protocolown'],
  components: {SettingTherapyProtocolOwnListForm},
  data() {
    return {
      currentRow: null,
      showForm: false
    }
  },
  mounted: function () {
    this.init()
  },
  computed: {
  },
  watch: {
    'protocolown': function() {
      this.init()
    },
    'modelValue': function() {
      this.init()
    }
  },
  methods: {
    init() {
      this.showForm = this.modelValue
      this.currentRow = this.protocolown
    },
    closeDialog() {
      this.showForm = false
      this.currentRow = null
      this.$emit('update:modelValue', false)
      this.$emit('after-save')
    },
  }
}
</script>
<style scoped>
</style>
