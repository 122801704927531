<template>
  <div>

    <el-dialog
        v-if="currentRow"
        :title="'Standort ' + currentRow.title + (currentRow.id ? ' bearbeiten' : ' hinzufügen')"
        v-model="showEditForm">

      <el-tabs>
        <el-tab-pane label="Stammdaten">
          <el-row class="mt-4">
            <el-col :span="24">
              <h6>Bezeichnung</h6>
              <el-input
                placeholder="Bezeichnung"
                v-model="currentRow.title"></el-input>
            </el-col>
          </el-row>




          <el-row class="mt-4">
            <el-col :span="24">
              <h6>BSN</h6>
              <el-input
                placeholder="BSN"
                v-model="currentRow.bsn"></el-input>
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="Adresse & Kontakt">
          <el-row class="mt-4">
            <el-col :span="24">
              <h6>Anschrift</h6>
              <el-input
                placeholder="Anschrift"
                v-model="currentRow.address"></el-input>
            </el-col>
          </el-row>
          <el-row class="mt-4">
            <el-col :span="10">
              <h6>PLZ</h6>
              <el-input
                placeholder="PLZ"
                v-model="currentRow.zipcode"></el-input>
            </el-col>
            <el-col :span="13" :offset="1">
              <h6>Ort</h6>
              <el-input
                placeholder="Ort"
                v-model="currentRow.city"></el-input>
            </el-col>
          </el-row>

          <el-row class="mt-4">
            <el-col :span="10">
              <h6>Telefon</h6>
              <el-input
                placeholder="Telefon"
                v-model="currentRow.phone"></el-input>
            </el-col>
            <el-col :span="13" :offset="1">
              <h6>Fax</h6>
              <el-input
                placeholder="Fax"
                v-model="currentRow.fax"></el-input>
            </el-col>
          </el-row>
          <el-row class="mt-4">
            <el-col :span="24">
              <h6>E-Mail</h6>
              <el-input
                placeholder="E-Mail Adresse"
                v-model="currentRow.email"></el-input>
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="Bankdaten">

          <el-row class="mt-4">
            <el-col :span="10">
              <h6>Name der Bank</h6>
              <el-input
                placeholder="Name der Bank"
                v-model="currentRow.bank_name"></el-input>
            </el-col>
            <el-col :span="13" :offset="1">
              <h6>Konto Inhaber</h6>
              <el-input
                placeholder="Konto-Inhaber"
                v-model="currentRow.bank_owner"></el-input>
            </el-col>
          </el-row>

          <el-row class="mt-4">
            <el-col :span="10">
              <h6>IBAN</h6>
              <el-input
                placeholder="IBAN"
                v-model="currentRow.bank_iban"></el-input>
            </el-col>
            <el-col :span="13" :offset="1">
              <h6>BIC</h6>
              <el-input
                placeholder="BIC"
                v-model="currentRow.bank_bic"></el-input>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>


      <template #footer>
        <el-row>
          <el-col :span="10" class="text-left">
            <el-popconfirm
                v-if="currentRow.id"
                title="Sie möchten diesen Standort löschen?"
                confirmButtonText='Ja, löschen'
                confirmButtonType="danger"
                cancelButtonText='Nein'
                iconColor="red"
                @confirm="onRowDeleteConfirmed(currentRow)">
              <template #reference>
                <el-button type="danger">Löschen</el-button>
              </template>
            </el-popconfirm>
          </el-col>
          <el-col :span="14" class="dialog-footer text-right">
            <el-button @click="showEditForm = false">Abbrechen</el-button>
            <el-button type="primary" @click="saveRow()">{{(currentRow.id ? ' Speichern' : 'Hinzufügen')}}</el-button>
          </el-col>
        </el-row>

      </template>
    </el-dialog>

    <el-button type="primary" @click="onBtnNewClick" size="mini" icon="el-icon-circle-plus">Standort hinzufügen</el-button>

    <el-table
        ref="doctorTable"
        :data="tableData"
        :default-sort = "{prop: 'title', order: 'ascending'}"
        style="width: 100%"
        highlight-current-row
        @row-click="onRowEditClick"
        stripe>
      <el-table-column
          prop="title"
          label="Titel"
          sortable>
      </el-table-column>
      <el-table-column
          width="150">
        <template #default="scope">
          <div class="text-right">
            <el-button size="mini" type="warning" @click="onRowEditClick(scope.row)" icon="el-icon-edit"></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          width="70">
        <template #default="scope">
          <div class="text-right">
            <el-popconfirm
                :title="'Sie möchten diesen Diagnostiker löschen?'"
                confirmButtonText='Ja, löschen'
                confirmButtonType="danger"
                cancelButtonText='Nein'
                iconColor="red"
                @confirm="onRowDeleteConfirmed(scope.row)">
              <template #reference>
                <el-button size="mini" type="warning" icon="el-icon-delete"></el-button>
              </template>
            </el-popconfirm>

          </div>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
const _ = require('lodash');

export default {
  name: "SettingSubsidiary",
  props: [],
  components: {},
  data() {
    return {
      tableData: [],
      currentRow: null,
      showEditForm: false
    }
  },
  mounted: async function () {
    await this.getSubsidiaries()
  },
  computed: {
    ...mapGetters('subsidiaries', ['subsidiaries']),
  },
  watch: {
    subsidiaries: function (val) {
      this.tableData = _.cloneDeep(val)
    }
  },
  methods: {
    ...mapActions('subsidiaries', ['getSubsidiaries', 'deleteSubsidiary', 'saveSubsidiary']),
    async onRowDeleteConfirmed(row) {
      await this.deleteSubsidiary(row)
      this.showEditForm = false
      await this.getSubsidiaries()
    },
    onBtnNewClick() {
      this.currentRow = {
        id: null,
        title: ''
      }
      this.showEditForm = true

    },
    onRowEditClick(row) {
      this.currentRow = row
      this.showEditForm = true
    },
    async saveRow() {
      await this.saveSubsidiary(this.currentRow)
      this.showEditForm = false
      await this.getSubsidiaries()
    }
  }
}
</script>
<style scoped>
</style>
