<template>
  <div>
    <el-row>
      <el-col :span="12">
        <h4 class="mt-2 mb-3">Knowledgebase Sync</h4>
        <p>Starten Sie hier die manuelle Synchronisation der DACY-Knowledgebase</p>
        <el-popconfirm
            class="mt-3"
            title="Möchten Sie die Synchronisation starten?"
            confirmButtonText='Ja, Synchronisation starten'
            confirmButtonType="warning"
            cancelButtonText='Nein'
            iconColor="warning"
            @confirm="startSync">
          <template #reference>
            <el-button :disabled="syncStarted" type="warning">Synchronisation starten</el-button>
          </template>
        </el-popconfirm>

        <el-alert type="success" v-if="syncStarted" class="mt-3">Synchronisations-Vorgang wurde gestartet, Sie erhalten eine seperate Nachricht bei Fertigstellung. Sie können mit DACY weiterarbeiten.</el-alert>

      </el-col>
    </el-row>

  </div>
</template>

<script>
import axios from "axios";
import socket from "@/socket";

export default {
  name: "SettingSync",
  props: [],
  components: {},
  data() {
    return {
      syncStarted: false,
    }
  },
  mounted: async function () {

    socket.on('sync-database-finished', () => {
      this.syncStarted = false
    })
    socket.on('sync-database-error', () => {
      this.syncStarted = false
    })

  },
  computed: {
  },
  watch: {
  },
  methods: {
    async startSync() {
      this.syncStarted = true
      await axios.get('/sync/database/kb')
    }
  }
}
</script>
<style scoped>
</style>
